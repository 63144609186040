
function About({ store }) {


    return <div className="About center-block reveal" id="about">
        <h1 className="title">WHO WE ARE</h1>
        <p dangerouslySetInnerHTML={{ __html: store.about }}></p>
    </div>

}

export default About;