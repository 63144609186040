import React, { useState, useEffect } from "react";
import Axios from "./../config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";
import TwitterShareButton from "react-share/lib/TwitterShareButton";
import TwitterIcon from "react-share/lib/TwitterIcon";
import WhatsappShareButton from "react-share/lib/WhatsappShareButton";
import WhatsappIcon from "react-share/lib/WhatsappIcon";
import { FaShareAlt } from "react-icons/fa";
import Swal from "sweetalert2";

function Element({ element, sid, deleteElem, index, setLoggedIn, store }) {
  const [deleting, setDeleting] = useState("Delete");
  const deleteItem = async () => {
    let sres = await Swal.fire({
      // title: 'Are you sure?',
      text: "Are you sure you want to delete this item!",
      //   icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (sres.isConfirmed) {
    } else {
      return;
    }

    if (deleting === "Deleting ...") {
      return;
    }
    setDeleting("Deleting ...");

    Axios.delete("/user/admin/item/" + sid, {
      data: { index: index },
      headers: {
        authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (response.data.success) {
          deleteElem(response.data.data);
          toast.success("Item Deleted Successfully!");
        } else {
          toast.error("Unable to Delete Blog Post!");
        }
        setDeleting("Delete");
      })
      .catch((err) => {
        setLoggedIn(false);
        setDeleting("Delete");
      });
  };

  const url = "https://store.bunika.co.ke/" + store.link;

  return (
    <div className="element">
      {
        <div className="text-light bg-dark p-3 rounded-2">
          <div>
            <div className="admin-item">
              <img src={element.itemImage} alt="Item" />
              <p>{element.name}</p>
              <p>{element.description}</p>
              <button className="btn btn-danger" onClick={deleteItem}>
                {deleting}
              </button>
              <div className="share-links px-2 bg-dark">
                <FaShareAlt
                  className="text-light"
                  style={{ height: 30 + "px" }}
                ></FaShareAlt>
                <FacebookShareButton
                  url={url}
                  quote={element.description}
                  hashtag={"#" + store.name}
                >
                  <FacebookIcon size="2em" round={true}></FacebookIcon>
                </FacebookShareButton>
                <TwitterShareButton
                  url={url}
                  quote={element.description}
                  hashtag={"#" + store.name}
                >
                  <TwitterIcon size="2em" round={true}></TwitterIcon>
                </TwitterShareButton>
                <WhatsappShareButton
                  url={url}
                  quote={element.description}
                  hashtag={"#" + store.name}
                >
                  <WhatsappIcon size="2em" round={true}></WhatsappIcon>
                </WhatsappShareButton>
              </div>
            </div>
          </div>
        </div>
      }
      <ToastContainer />
    </div>
  );
}

export default Element;
