

function Nav({ store }) {
    return (
        <nav className="Nav">
            <ul>
                <li><a href='#about'>ABOUT&nbsp;US</a></li>
                <li><a href='#items'>{store.type.toUpperCase()}</a></li>
                <li><a href='#contacts' className='highlight btn'>CONTACT</a></li>
            </ul>
        </nav>
    );
}

export default Nav;