import Nav from './Nav'

function Banner({ store }) {
    return (
        <div className="Banner">
            <img src={store.bannerImage} className="Banner-Image" alt="Banner" />
            <div className={"overlay overlay-" + store.overlay}>
                <Nav store={store} />
                <div className="Tag m-1">
                    <h1>{store.name}</h1>
                    <p>{store.tagline}</p>
                </div>
            </div>
        </div>
    );
}

export default Banner;