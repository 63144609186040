
function Item({ item }) {
    return <div>
        <div className="item">
            <img src={item.itemImage} alt="Item" />
            <h2>{item.name}</h2>
            <p>{item.description}</p>
            {/* <p>{item.price}</p> */}
        </div>
    </div>

}

export default Item;