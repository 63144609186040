// import logo from './logo.svg';
//<img src={logo} className="App-logo" alt="logo" />
import "./App.css";
import "./styles.css";
import React, { useEffect, useState } from "react";
import notfound from "./notfound.png";
import Axios from "./config/axios.config";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from "./Components/Page";
import Admin from "./Components/Admin";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [store, setStore] = useState(null);
  const [error, setError] = useState(false);
  let query = window.location.href.split("/");
  let id = query[query.length - 1].toLowerCase();
  if (id === "admin") {
    id = query[query.length - 2];
  }

  useEffect(() => {
    let isCancelled = false;
    let query = window.location.href.split("/");
    let id = query[query.length - 1].toLowerCase();
    if (id === "admin") {
      id = query[query.length - 2];
    }

    const handleChange = async () => {
      let response = await Axios.get("/business/t1/" + id);

      if (!isCancelled) {
        if (!response.data) {
          setError(true);
          return;
        }
        let mstore = response.data;
        setStore(mstore);
      }
    };
    if (id) {
      handleChange();
    } else setError(true);

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (store != null) setLoaded(true);
  }, [store]);

  return (
    <div>
      {loaded ? (
        <Router>
          <div className="App">
            <Routes>
              <Route
                path={"/" + id + "/admin"}
                element={<Admin store={store} />}
              />
              <Route path={"/" + id} element={<Page store={store} />} />
            </Routes>
          </div>
        </Router>
      ) : (
        <div>
          {error ? (
            <div className="loading-screen">
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              {/* <div><img src={notfound} width="150px" alt="Not Found" /></div> */}
              {/* <h3>Error.</h3><h3>We Couldn't find the page you are looking for</h3> */}
            </div>
          ) : (
            <div className="loading-screen">
              <h3>Loading</h3>
              <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default App;
