import { FaInstagram } from "react-icons/fa"
import FacebookIcon from 'react-share/lib/FacebookIcon';
import TwitterIcon from 'react-share/lib/TwitterIcon';
import LinkedinIcon from 'react-share/lib/LinkedinIcon';


function Contact({ store }) {

    return <div className="contacts" id="contacts">
        <h2 className="">GET IN TOUCH</h2>
        <div className="info">
            <div className="availability">
                <h3>Hours Available</h3>
                <p>Weekdays: {store.mon_to_fri}</p>
                <p>Saturday: {store.saturday}</p>
                <p>Sunday: {store.sunday} </p>
                <p>Holidays: {store.holidays} </p>
            </div>
            <div className="contact">
                <p>Phone: {store.phone}</p>
                <p>Email: {store.email}</p>
                <p>Address: {store.address}</p>
            </div>
        </div>
        <div className="socials">
            <a href={store.fblink} role="button">
                <FacebookIcon size="2em" round={true} />
            </a>

            <a href={store.twitterlink} role="button">
                <TwitterIcon size="2em" round={true} />
            </a>
            <a href={store.iglink} role="button">
                <FaInstagram size="2em" />
            </a>
            <a href={store.linkedInlink} role="button">
                <LinkedinIcon size="2em" round={true} />
            </a>
        </div>
    </div>
}

export default Contact;