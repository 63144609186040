import Banner from "./Page/Banner";
import About from "./Page/About";
import Sample from "./Page/Sample";
import Contact from "./Page/Contacts";
import { motion, useScroll, useTransform } from "framer-motion";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

function Page({ store }) {
  useEffect(() => {
    window.addEventListener("scroll", reveal);
    window.addEventListener("load", reveal);

    reveal();

    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var winHeight = window.innerHeight;
        var revealTop = reveals[i].getBoundingClientRect().top;
        var revealpoint = 100;

        if (revealTop < winHeight - revealpoint) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }
  });

  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "50%"]);

  return (
    <div>
      <Helmet>
        <title>{store.name}</title>
        <meta name="description" content={store.tagline} />
      </Helmet>
      <Banner store={store} />
      <About store={store} />
      <div className="band">
        <motion.div style={{ y }} className="m-div">
          <img src={store.bandImage} className="band-image" alt="band" />
        </motion.div>
      </div>
      <Sample store={store} />
      <Contact store={store} />
    </div>
  );
}

export default Page;
