import Item from "./Item";

function Sample({ store }) {
  return (
    <div className="Sample center-block reveal" id="items">
      <h1 className="title">SOME OF OUR {store.type.toUpperCase()}</h1>
      <div className={store.type + " items"}>
        {store.items.map((item, key) => (
          <Item item={item} key={key} id={item.name} />
        ))}
      </div>
    </div>
  );
}

export default Sample;
