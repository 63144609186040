import Element from "./Element";
import React, { useState } from "react";
import Axios from "./../config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import TwitterShareButton from "react-share/lib/TwitterShareButton";
import TwitterIcon from "react-share/lib/TwitterIcon";
import WhatsappShareButton from "react-share/lib/WhatsappShareButton";
import WhatsappIcon from "react-share/lib/WhatsappIcon";
import { FaShareAlt } from "react-icons/fa";
import { FacebookShareButton } from "react-share";
import { FacebookIcon } from "react-share";

function Admin({ store }) {
  const [items, setItems] = useState(store.items);

  const [loggedIn, setLoggedIn] = useState(
    window.localStorage.getItem("token") != null
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [adding, setAdding] = useState("Add");

  const addItem = () => {
    if (adding !== "Add") {
      return;
    }

    if (title === "" || description === "") {
      toast.error("Please fill in all the fields!");
      return;
    }

    if (image == null) {
      toast.error("Please provided an image");
      return;
    }
    setAdding("Uploading Item...");
    const fd = new FormData();

    fd.append("itemImage", image);
    fd.append("description", description);
    fd.append("name", title);

    Axios.post("/user/admin/item/" + store._id, fd, {
      headers: {
        authorization: "Bearer " + window.localStorage.getItem("token"),
      },
      onUploadProgress: (ProgressEvent) => {
        setAdding(
          "Upload progress: " +
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
            "%"
        );
      },
    })
      .then((response) => {
        if (response.data.success) {
          setItems([...items, response.data.data]);
          toast.success("New Item Added!");
          setTitle("");
          setDescription("");
          setImage(null);
        } else {
          toast.error(response.data.error);
        }
        setAdding("Add");
      })
      .catch((err) => {
        setLoggedIn(false);
        setAdding("Add");
      });
  };

  const deleteElem = (items) => {
    setItems(items);
  };

  const [pass, setPassword] = useState("");
  const [err, setError] = useState("");
  const login = async () => {
    setError("");
    let res = await Axios.post("/user/admin/login/t1/" + store._id, {
      password: pass,
    });

    if (res.data.success) {
      setLoggedIn(true);
      window.localStorage.setItem("token", res.data.accessToken);
    } else {
      setError("Invalid Password");
    }
  };

  const url = "https://store.bunika.co.ke/" + store.link;

  return (
    <div className="admin">
      <div className="nav">
        <div className="share-links px-2 bg-dark">
          <FaShareAlt
            className="text-light"
            style={{ height: 30 + "px" }}
          ></FaShareAlt>
          <FacebookShareButton
            url={url}
            quote={store.tagline}
            hashtag={"#" + store.name}
          >
            <FacebookIcon size="2em" round={true}></FacebookIcon>
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            quote={store.tagline}
            hashtag={"#" + store.name}
          >
            <TwitterIcon size="2em" round={true}></TwitterIcon>
          </TwitterShareButton>
          <WhatsappShareButton
            url={url}
            quote={store.tagline}
            hashtag={"#" + store.name}
          >
            <WhatsappIcon size="2em" round={true}></WhatsappIcon>
          </WhatsappShareButton>
        </div>
        <Link to={"/" + store.link}>
          <button>Back to Home Page</button>
        </Link>
      </div>
      {!loggedIn ? (
        <div className="container login-cont">
          <div className="login">
            <h2>Admin Login</h2>
            <p>Password</p>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button className="btn btn-primary" onClick={login}>
              Log In
            </button>
            <br />
            {err.length > 0 ? (
              <div className="bg-danger text-white error">{err}</div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="container">
          <h1>{store.name + " Admin"}</h1>
          <div className="form bg-light p-3 user-admin">
            <h2>Add Item</h2>
            <div className="form-fields">
              <div>
                <p>Item Image</p>
                <input
                  type="file"
                  onChange={(event) => {
                    setImage(event.target.files[0]);
                  }}
                />
              </div>
              <div>
                <p>Item Name</p>
                <input
                  value={title}
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
              </div>
              <div className="t-area">
                <p>Item Description</p>
                <textarea
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                  }}
                  maxLength="150"
                ></textarea>
              </div>
            </div>
            <div className="text-center">
              <button onClick={addItem} className="btn btn-primary mt-3">
                {adding}
              </button>
            </div>
          </div>
          <h2 className="text-center mt-5">
            {"My " + store.type + " Catalogue"}
          </h2>
          <div className="admin-items">
            {items.map((elem, key) => (
              <Element
                store={store}
                setLoggedIn={setLoggedIn}
                key={elem._id}
                index={key}
                element={elem}
                sid={store._id}
                type={store.type}
                deleteElem={deleteElem}
              />
            ))}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Admin;
